import {createSlice} from "@reduxjs/toolkit"

const initialState={
    category: null, 
    links:null,
    linkpage: null,
}


const mainData = createSlice({
    name: "maindata",
    initialState,
    reducers: {
        setData(state, action) {
            state.category = action.payload.projectcats;
            state.links = action.payload.links;
            state.linkpage = action.payload.linkpage;
        }
    }
})




export const MainDataActions = mainData.actions

export default mainData.reducer