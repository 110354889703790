import React,{} from 'react';
import { Col, Container, Row } from 'reactstrap';
import './BuildTimesless.scss';

const BuildTimesless = () => {
    // const [active, setActive] = useState(false)
    // onClick={setActive(!active)} className={active? 'bckground' : ''}
  return (
    <div className='buildTimesless'>
        <Container>
            <div className='text-build'>
                <Row>
                    <Col md={6} sm={12}>
                        <h2>We build timeless experiences</h2>
                    </Col>
                    <Col md={6} sm={12}>
                        <p>
                            We’ve developed three specific use cases where our approach and expertise combined help businesses to truly excel.
                        </p>
                    </Col>
                </Row>
            </div>
            <div className='menu'>
                <ul>
                    <li className='active'>All</li>
                    <li>Dashboard design</li>
                    <li>App design</li>
                    <li>Website design</li>
                    <li>Branding</li>
                    <li>UX Case study</li>
                    <li>In-house projects</li>
                </ul>
            </div>
        </Container>
    </div>
  )
}

export default BuildTimesless