import React, {useEffect, useState} from 'react'
import { Container } from 'reactstrap'
import './links.scss'
import { useDispatch, useSelector } from 'react-redux'
import {LinkActive} from '../../../store/linkactive'

const Links = () => {
    const dispatch = useDispatch()
    let linksUi = useSelector(state => state.main.links);
    let linkPageUi = useSelector(state => state.main.linkpage);
    let [links, setLinks] = useState([]);
    let [linkPage, setLinkPage] = useState([]);
    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(LinkActive.setType('links'))
        if (linksUi && linkPageUi){
            setLinks(linksUi);
            setLinkPage(linkPageUi)
        }

    },[linksUi,linkPageUi,dispatch])
    // console.log(links);
    
    return (
        <Container className='p-0'>
            <div className='links_header_container'>
                {/* top-right */}
                <img className='absolute__imgs' 
                    // src='https://365design.layouti.com/wp-content/uploads/2021/10/Behance.jpg' 
                    src={linkPage.topRightImage}
                    alt="topRightImage" 
                />
                
                {/* top-left */}
                <img className='absolute__imgs' 
                    // src='https://365design.layouti.com/wp-content/uploads/2021/09/Dribbble-1-300x225.jpg' 
                    src={linkPage.topLeftImage}
                    alt="topLeft" />

                {/* bottom-left */}
                <img className='absolute__imgs' 
                    // src='https://365design.layouti.com/wp-content/uploads/2021/10/Behance1-300x235.jpg' 
                    src={linkPage.bottomLeftImage}
                    alt="bottomLeftImage" />

                {/* bottom-right */}
                <img className='absolute__imgs' 
                    // src='https://365design.layouti.com/wp-content/uploads/2021/10/home-maintenance-services-app.png' 
                    src={linkPage.bottomRightImage}
                    alt="bottomRight" />
                
                {/* emoji-right */}
                <img className='emoji__imgs' src='https://365design.layouti.com/wp-content/uploads/2021/09/%E2%89%A1%C6%92yy.png' alt="" />
                {/* emoji-left */}
                <img className='emoji__imgs' src='https://365design.layouti.com/wp-content/uploads/2021/09/%E2%89%A1%C6%92ye.png' alt="" />
                
                <div className='links_header text-center mt-5'>
                    <img src="https://365design.layouti.com/wp-content/uploads/2021/10/yaser.png" alt=""/>
                    <h3 className='fs-1 mt-3'>
                        {/* Product Experience Manager @ Publicis Sapient */}
                        {linkPage.titltEn}
                    </h3>
                    <p className='fs-4 d-block mt-3'>
                        {/* UI/UX designer creates glad visual identities that please user’s brain & eyes */}
                        {linkPage.descriptionEn}
                    </p>
                </div>
            </div>
            <div className='social_media_links'>
                    <h1 className=' text-center'>Social Media Links</h1>
                    <div className='links'>
                        {
                            links.map(el=> (
                                <a key={el.id} href={el.link} alt={el.titleEn} className='social_media_link'>
                                    {el.titleEn}
                                </a>
                            ))
                        }
                        {/* <a className='social_media_link' href='/*'>CV 2022</a>
                        <a className='social_media_link' href='/*'>Layouti Website</a>
                        <a className='social_media_link' href='/*'>Learn UI Design</a>
                        <a className='social_media_link' href='/*'>Linkedin</a>
                        <a className='social_media_link' href='/*'>Dribbble</a>
                        <a className='social_media_link' href='/*'>Instagram</a>
                        <a className='social_media_link' href='/*'>Facebook</a>
                        <a className='social_media_link' href='/*'>Behance</a>
                        <a className='social_media_link' href='/*'>My blog</a> */}
                    </div>
            </div>
        </Container>
    )
}

export default Links