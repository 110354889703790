import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Projects.scss';
import Project1 from '../../../../assets/images/works-project1.png';
import Project2 from '../../../../assets/images/works-project2.PNG';

const Projects = () => {
  return (
    <div className='projects'>
        <Container>
            <Row>
                <Col md={6}>
                    <Row>
                        <div className='project'>
                            <img src={Project1} className='img-fluid' alt="proj1"/>
                        </div>
                    </Row>
                    <Row>
                        <div className='project-name'>
                            <h6>Project name</h6>
                            <p>Mobile</p>
                        </div>
                    </Row>
                </Col>
                <Col md={6} className='proj-bottom'>
                    <Row>
                        <div className='project2'>
                            <img src={Project2} className='img-fluid' alt="proj2"/>
                        </div>
                    </Row>
                    <Row>
                        <div className='project-name'>
                            <h6>Project name</h6>
                            <p>Mobile</p>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Projects