import React from 'react'
import BuildTimesless from './BuildTimesless/BuildTimesless'
import HeaderContent from './HeaderContent/HeaderContent'
import Projects from './Projects/Projects'

const Works = () => {
    return (
        <div>
            <HeaderContent />
            <BuildTimesless />
            <Projects />
        </div>
    )
}

export default Works