import React from 'react';
import { Container } from 'reactstrap';
import './HeaderContent.scss';

const HeaderContent = () => {
  return (
    <div className='headerContent'>
        <Container>
            <div className='home-text'>
                <h2>Check out some of the most smashing projects we helped create</h2>
                <button className='see-projects'>See our work</button>
            </div>
        </Container>
    </div>
  )
}

export default HeaderContent